import { Container, Heading, Text } from "@chakra-ui/react"
import { graphql } from "gatsby"
import * as React from "react"
import { useTranslation } from "react-i18next"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title="404 Page Not Found" />
      <Container textAlign="center" py="32">
        <Heading my="4">{t("title")}</Heading>
        <Text color="font.dark">{t("description")}</Text>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "not_found"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
